import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeLogger } from 'ngrx-store-logger';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({}, {
      metaReducers: [storeLogger()],
      runtimeChecks: {
        strictActionWithinNgZone: true,
        strictActionImmutability: false,
        strictStateImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: false, // Restrict extension to log-only mode
    }),
  ],
  providers: []
})
export class NgrxCoreModule {
}
